import { shallowRef} from 'vue'
import KnowledgebaseWindow from '../components/custom/Actions/KnowledgebaseWindow.vue'

export default {
    data() {
        return {
            _knowledgebaseWindowShow: false,
        }
    },
    computed: {
        showFullEditLink() {
            
            switch(this._data._class) {
                case 'Mail':
                case 'MailTemplateBlock':
                case 'BaseCourseAction':
                case 'Mailtemplate':
                case 'Enrollment':
                case 'KnowledgebaseItem':
                case 'PlanningAction':
                    return false;
                    break;
                default:
                    return true;
                    break;
            }
        },
    },
    methods: {
        
        getEditSubTitle() {
            
            var entityName = this._entity._class;
            switch(this._entity._class) {
                case 'BaseCourse':            entityName = 'Cursusgegevens'; break;
                case 'Course':                entityName = 'Cursusgegevens'; break;
                case 'CourseLocation':        entityName = 'Locatiegegevens'; break;
                case 'Customer':              entityName = 'Klantgegevens'; break;
                case 'Participant':           entityName = 'Cursistgegevens'; break;
                case 'User':                  entityName = 'Gegevens'; break;
                case 'MailTemplate':          entityName = 'Mailtemplate'; break;
                case 'MailTemplateBlock':     entityName = 'Tekstblok gegevens'; break;
                case 'Enrollment':            entityName = 'Inschrijving'; break;
                case 'KnowledgebaseItem':     entityName = 'Kennisbank artikel'; break;
                case 'PlanningAction':        entityName = ''; break;
                
            }
            return entityName;
            
        },
        getEditTitle() {
            var entityName = this._entity._class;
            switch(this._entity._class) {
                case 'BaseCourse':          entityName = 'Cursus'; break;
                case 'BaseCourseAction':    entityName = 'Cursusactie'; break;
                case 'Course':              entityName = 'Cursus'; break;
                case 'CourseLocation':      entityName = 'Cursuslocatie'; break;
                case 'Customer':            entityName = 'Klant'; break;
                case 'Participant':         entityName = 'Cursist'; break;
                case 'User':                entityName = 'Gebruiker'; break;
                case 'MailTemplate':        entityName = 'Mailtemplate'; break;
                case 'MailTemplateBlock':   entityName = 'Mailtemplate blok'; break;
                case 'Enrollment':          entityName = 'Inschrijving'; break;
                case 'KnowledgebaseItem':   entityName = 'Artikel'; break;
                case 'PlanningAction':      entityName = 'Planning-activiteit'; break;
                
            }
            return entityName;
            
        },
        getEditName(data) {
            if (!data.id) return '';
            var html = [];

            
            switch(data._class) {
                case 'BaseCourse':
                case 'Course':
                case 'CourseLocation':
                case 'Customer':    
                    html.push(this._data.name+' - ID: '+this._data.id);
                    break;
                case 'Participant':    
                    html.push(this._data.fullname+'  ('+this._data.Customer.name+')');
                    break;
                case 'User':    
                    html.push(this._data.fullname+' - ID: '+this._data.id);
                    break;
                case 'MailTemplate':    
                    html.push((this._data.BaseCourse.acronym?this._data.BaseCourse.acronym:'Default')+(this._data.type?' - '+this._data.type+' template':''));
                    break;
                case 'Enrollment':    
                    html.push(this._data.Participant.fullname+'  ('+this._data.Customer.name+')');
                    break;
                case 'BaseCourseAction':    
                    html.push(this._data.BaseCourse.name);
                    break;
            }
            return html.join('<br />');
        },

        getEndtime(date, time, hours) {
            //debugger
            //console.log(item)
            // if (!date) {
            //     date = item.startdate.substr(0,10);
            // }

            date = date.substr(0,10);

            const dateTime = date+' '+time+':00';
            let duration = hours;
            let addHours = Math.floor(duration);
            let addMinutes = Math.round((duration-addHours)*60);
            addMinutes+=addHours*60;
            
        
            let dateTimeParts= dateTime.split(/[- :]/); // regular expression split that creates array with: year, month, day, hour, minutes, seconds values
            dateTimeParts[1]--; // monthIndex begins with 0 for January and ends with 11 for December so we need to decrement by one
        
            const dateObject = new Date(...dateTimeParts);
        
            dateObject.setMinutes(dateObject.getMinutes() + addMinutes);
        
            //var newDateObj = moment(dateObject).add(addMinutes, 'm').toDate();
            return dateObject.toTimeString().substr(0,5);
        },

        getDayname(day) {
            switch(day) {
              case 0:
                return 'Zondag';
              case 1:
                return 'Maandag';
              case 2:
                return 'Dinsdag';
              case 3:
                return 'Woensdag';
              case 4:
                return 'Donderdag';
              case 5:
                return 'Vrijdag';
              case 6:
                return 'Zaterdag';
            }
        },

        showKnowledgebaseWindow() {
            //if (!this._knowledgebaseWindowShow) {
            if (document.getElementById('dialog') && !document.getElementById('dialog').open) {
                //console.log('show _knowledgebaseWindowShow')

                this._knowledgebaseWindowShow = true;

                var dialogConfig = {
                    title:'Kennisbank',
                    component:shallowRef(KnowledgebaseWindow),
                }
                
                this.dialogForm = {};
                this.emitter.emit('showDialog', dialogConfig);
            }
            else {
                this._knowledgebaseWindowShow = false;
                this.closeDialog()
            }
        },

        handleKeydown(event) {
            if ((event.ctrlKey || event.metaKey) && event.key === 'i') {
              event.preventDefault();
              //console.log('CTRL-I !')
              this.showKnowledgebaseWindow();
              //this.onCtrlI();
            }
        },
    },

}
