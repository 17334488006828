<template>
    <h3 class="panelSubtitle">{{$props.context.attrs.title}}</h3>

    <div ref="repeaterContainer">
        
        <div v-if="ready" v-for="(item, itemIndex) in itemValues" class="panelFormInfo repeaterContainer">
            <!-- {{ $props.context.node.parent._value[this.$props.context.attrs.repeatername][itemIndex] }} -->
            <FormKit 
                :id="uniqueId+'_'+itemIndex"
                type="group"
                @input="updateItem(itemIndex, $event)"
                v-model="itemValues[itemIndex]" 
                :actions="false"
    >
                
                <FormKitSchema
                    :schema="$props.context.attrs.fields"
                />
            </FormKit>

            <ul>
                <li class="downButton">
                    <button @click.prevent="moveDown(itemIndex)"><i class="fa-solid fa-down"></i></button>
                </li>
                <li class="upButton">
                    <button @click.prevent="moveUp(itemIndex)"><i class="fa-solid fa-up"></i></button>
                </li>
                <li class="removeButton">
                    <button @click.prevent="remove(itemIndex)"><i class="fa-solid fa-trash"></i></button>
                </li>
            </ul>
            
            

        </div>
    </div>
    <br />
    <button class="btn lineBtn lineBtnFontAwesomeTag" @click.prevent="addItem"><i class="fa-solid fa-plus"></i> Toevoegen</button>  
</template>

<script>
  
import { ref } from 'vue';

export default {
  components: {},
  props: {
      context: {
          type:Object,
          required:true
      },
  },
  data() {
      return {
        ready:false,
        items:[],
        itemValues:[],
        uniqueId:0
      }
  },
  computed: {

  },
  methods: {
    refresh() {
        // get height of repeaterContainer
        let height = this.$refs.repeaterContainer.clientHeight;
        // set height of repeaterContainer
        this.$refs.repeaterContainer.style.height = height+'px';
        this.ready = false;
        setTimeout(()=>{
            this.ready = true;
            this.$refs.repeaterContainer.style.height = 'auto';
        }, 10);
    },
    moveUp(index) {
        console.log('moveUp', index);
        
        if (index > 0) {
            // swap in this.$props.context.node.parent._value[this.$props.context.attrs.repeatername]
            const temp = this.$props.context.node.parent._value[this.$props.context.attrs.repeatername][index];
            this.$props.context.node.parent._value[this.$props.context.attrs.repeatername][index] = this.$props.context.node.parent._value[this.$props.context.attrs.repeatername][index-1];
            this.$props.context.node.parent._value[this.$props.context.attrs.repeatername][index-1] = temp;

            this.refresh();
        }
    },
    moveDown(index) {
        console.log('moveDown', index);
        if (index < this.$props.context.node.parent._value[this.$props.context.attrs.repeatername].length-1) {
            // swap in this.$props.context.node.parent._value[this.$props.context.attrs.repeatername]
            const temp = this.$props.context.node.parent._value[this.$props.context.attrs.repeatername][index];
            this.$props.context.node.parent._value[this.$props.context.attrs.repeatername][index] = this.$props.context.node.parent._value[this.$props.context.attrs.repeatername][index+1];
            this.$props.context.node.parent._value[this.$props.context.attrs.repeatername][index+1] = temp;

            this.refresh();
        }
    },
    remove(index) {
        console.log('remove', index);
        this.ready = false;
        let newItems = [];
        this.$props.context.node.parent._value[this.$props.context.attrs.repeatername].forEach((item, itemIndex)=>{
            if (itemIndex != index) {
                newItems.push(item);
            }
        });
        this.$props.context.node.parent._value[this.$props.context.attrs.repeatername] = newItems;

        setTimeout(()=>{
            this.ready = true;
        }, 10);
        
        //this.init();
    },

    addItem() {
        console.log('addItem');
        this.items.push({});
        this.itemValues.push({});
        this.handleInput(this.itemValues.length-1); // Zorg ervoor dat de form waarde wordt bijgewerkt
    },
    updateItem(index, event) {
        console.log('updateItem', index, event);

        // Maak een nieuwe kopie van de itemValues zonder de wijziging
        const updatedItem = { ...event };

        // Als de waarde in 'itemValues' is veranderd, werk het bij
        if (JSON.stringify(this.itemValues[index]) !== JSON.stringify(updatedItem)) {
            this.itemValues = [
                ...this.itemValues.slice(0, index),
                updatedItem,
                ...this.itemValues.slice(index + 1)
            ];

            // Alleen handleInput aanroepen als de waarde daadwerkelijk veranderd is
            this.handleInput(index);
        }
    },
    handleInput(index) {
        
        // append this.itemValues to parent node
        const currentValue = this.$props.context.node.parent._value[this.$props.context.attrs.repeatername];

        // Alleen bijwerken als de nieuwe waarde verschilt van de bestaande waarde
        if (JSON.stringify(currentValue) !== JSON.stringify(this.itemValues)) {
            console.log('handleInput', index, this.itemValues[index]);
            //this.$props.context.node.input(this.itemValues);

            this.$props.context.node.parent._value[this.$props.context.attrs.repeatername][index] = this.itemValues[index];
        }
        
        // this.$props.context.node.input(this.itemValues);
        // this.$props.context.node.input(this.itemValues);
    },
    


  },
  computed: {
    
    value() {
        return this.selectedValue;
        // var a = [];
        // this.selectedValue.forEach(id=>{
        //     a.push({id:id, name: this.options.find(o=>o.id==id).name})
        // })
        // return a;
    }
  },
  watch: {
    'context.node.parent._value': {
        handler(newVal) {
            const repeaterData = newVal[this.$props.context.attrs.repeatername] || [];

            // Alleen bijwerken als de data verschilt van de huidige itemValues
            if (JSON.stringify(repeaterData) !== JSON.stringify(this.itemValues)) {
                this.itemValues = repeaterData.map(item => ({ ...item }));
            }
        },
        deep: true,
        immediate: true
    },
    value(newVal, oldVal) {
        console.log('watch value', newVal, oldVal, this.selectedValue)
        //this.$props.context.node.input(newVal);
    },
    
  },
  
  created() {
        console.log('repeater field created', this.$props);

        this.uniqueId = Math.random().toString(36).substring(7);

        // Haal de repeater-data uit de parent node
        const repeaterData = this.$props.context.node.parent._value[this.$props.context.attrs.repeatername];

        if (Array.isArray(repeaterData) && repeaterData.length > 0) {
            this.itemValues = repeaterData.map(item => ({ ...item }));
        } else {
            this.itemValues = [];
        }

        console.log('Loaded values:', this.itemValues); // <-- Debug output
        this.ready = true;
 },
  beforeMount() {
        console.log('repeater field beforeMount', this.$props)


  },
  beforeUnmount() {

  },
  mounted() {
        //console.log('autocomplete mounted', this.$props.context._value)
        //debugger;
        
  }
}


</script>


<style scoped>
.panelFormInfo {
    border: 1px solid var(--input-field-line-color);
    background: none !important;
    padding-top: 1.5rem !important;
}
</style>