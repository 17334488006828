// moveLabelPlugin.js
import { clone } from '@formkit/utils'


const moveLabelPluginHasValue = (node) => {
  console.log(node, node.value)
  return "klaas"
}

export default function moveLabelPlugin (node) {
  node.on('created', () => {
    const inputDefinition = clone(node.props.definition)

    // add random unique identifier to id (for repeater fields)
    node.context.id+= '_'+Math.random().toString(36).substring(7)

    
    // submit added @klaas
    if (['radio', 'checkbox',  'tree', 'repeater', /*'autocomplete',*/ /*'filebrowser',*/ /*'select',*/ 'submit', 'color', /*'wysiwyg',*/ /*'display'*/].includes(node.props.type)) return
    const originalSchema = inputDefinition.schema
    const higherOrderSchema = (extensions) => {


      var attrs = {
        class: '$classes.floating',
        for: '$id'
      }



      switch (node.props.type) {
          case 'select':
          case 'autocomplete':
          
              if (!node.props.attrs.hasvalue) {
                attrs['data-has-value'] = '$_value !== "0" && $_value != 0 && $_value !== NULL && $_value !== undefined';
              }
              else {
                attrs['data-has-value'] = 'true';
              }
              break;

          case 'autocomplete':
              console.log('autocomplete_value', node)
              attrs['data-has-value'] = '$_value.length > 0';
              break;

          case 'autocomplete_multiple':
              attrs['data-has-value'] = '$_value.length > 0';
              break;

          case 'date':
          case 'time':
          case 'file':
              attrs['data-has-value'] = 'true';
              break;

          case 'filebrowser':
              extensions.label = { $el: null, children: null };
              attrs.style={display:'none'}
              extensions.suffix = {
                $el: 'label',
                children: '$label',
                attrs: attrs
              }
              return originalSchema(extensions)
              break;

          default:
              attrs['data-has-value'] = '$_value !== "" && $_value !== undefined';
              break;


      }

      //console.log(node.props)
      // if (node.props.type=='select' && !node.props.attrs.hasvalue) {
      //   attrs['data-has-value'] = '$_value !== "0" && $_value != 0 && $_value !== NULL && $_value !== undefined';
      // }
      // else if (node.props.type=='select' && node.props.attrs.hasvalue) {
      //   attrs['data-has-value'] = 'true';
      // }
      // else if (node.props.type=='autocomplete') {
      //   console.log('autocomplete_value', node.value)
      //   attrs['data-has-value'] = '$_value.length > 0';
      // }
      // else if (node.props.type=='autocomplete_multiple') {
      //   console.log('autocomplete_novalue', node.value)
        
      //   attrs['data-has-value'] = '$_value.length > 0';
        
      // }
      // else if (node.props.type=='date' || node.props.type=='time' || node.props.type=='file') {
      //   attrs['data-has-value'] = 'true';
      // }
      // else if (node.props.type=='filebrowser') {
      //   extensions.label = { $el: null, children: null };
      //   attrs.style={display:'none'}
      //   extensions.suffix = {
      //     $el: 'label',
      //     children: '$label',
      //     attrs: attrs
      //   }
      //   return originalSchema(extensions)
      // }
      // else {
      //   attrs['data-has-value'] = '$_value !== "" && $_value !== undefined';
      // }
      // This kills the original label
      extensions.label = { $el: null, children: null }
      extensions.suffix = {
        $el: 'label',
        children: '$label',
        attrs: attrs
      }
      return originalSchema(extensions)
    }
    
    inputDefinition.schema = higherOrderSchema
    node.props.definition = inputDefinition
  })
}