import { createStore } from 'vuex'

export default createStore({
  state: {
    loaderstate: false,
    login: {},
    lastlogin: false,
    settings: [],
    alertcounts: null,
    dashboardcourses: null,
    dashboardusers: null,
    mailtemplates: null,
    users: null,
    locations: []
  },
  getters: {
    get_loaderstate: state => { return state.loaderstate; },
    get_login: state => { return state.login; },
    get_lastlogin: state => { return state.lastlogin; },
    get_settings: state => { return state.settings; },
    get_alertcounts: state => { return state.alertcounts; },
    get_dashboardcourses: state => { return state.dashboardcourses; },
    get_dashboardusers: state => { return state.dashboardusers; },
    get_mailtemplates: state => { return state.mailtemplates; },
    get_users: state => { return state.users; },
    get_locations: state => { return state.locations; }
  },
  mutations: {
    SET_LOADERSTATE(state, val) { state.loaderstate = val; },
    SET_LOGIN(state, val) { state.login = val; },
    SET_LASTLOGIN(state, val) { state.lastlogin = val; },
    SET_SETTINGS(state, val) { state.settings = val; },
    SET_ALERTCOUNTS(state, val) { state.alertcounts = val; },
    SET_DASHBOARDCOURSES(state, val) { state.dashboardcourses = val; },
    SET_DASHBOARDUSERS(state, val) { state.dashboardusers = val; },
    SET_MAILTEMPLATES(state, val) { state.mailtemplates = val; },
    SET_USERS(state, val) { state.users = val; },
    SET_LOCATIONS(state, val) { state.locations = val; }
  },
  actions: {
    set_loaderstate({ commit }, val) { commit('SET_LOADERSTATE', val); },
    set_login({ commit }, val) { commit('SET_LOGIN', val); },
    set_lastlogin({ commit }, val) { commit('SET_LASTLOGIN', val); },
    set_settings({ commit }, val) { commit('SET_SETTINGS', val); },
    set_alertcounts({ commit }, val) { commit('SET_ALERTCOUNTS', val); },
    set_dashboardcourses({ commit }, val) { commit('SET_DASHBOARDCOURSES', val); },
    set_dashboardusers({ commit }, val) { commit('SET_DASHBOARDUSERS', val); },
    set_mailtemplates({ commit }, val) { commit('SET_MAILTEMPLATES', val); },
    set_users({ commit }, val) { commit('SET_USERS', val); },
    set_locations({ commit }, val) { commit('SET_LOCATIONS', val); }
  }
})